import { routes } from "@/app.routes";
import {
	HTTP_INTERCEPTORS,
	provideHttpClient,
	withFetch,
	withInterceptorsFromDi,
} from "@angular/common/http";
import type { ApplicationConfig } from "@angular/core";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import {
	provideRouter,
	withDisabledInitialNavigation,
	withEnabledBlockingInitialNavigation,
} from "@angular/router";
import {
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
	MsalBroadcastService,
	MsalGuard,
	type MsalGuardConfiguration,
	MsalInterceptor,
	type MsalInterceptorConfiguration,
	MsalService,
} from "@azure/msal-angular";
import {
	BrowserCacheLocation,
	BrowserUtils,
	type IPublicClientApplication,
	InteractionType,
	PublicClientApplication,
} from "@azure/msal-browser";
import { provideQueryDevTools } from "@ngneat/query-devtools";
import { environment } from "../environments/environment";

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: environment.msalConfig.auth.clientId,
			authority: environment.msalConfig.auth.authority,
			redirectUri: "/dashboard",
			postLogoutRedirectUri: "/",
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
		},
		system: {
			allowNativeBroker: false, // Disables WAM Broker
		},
	});
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set(environment.graphAPI.uri, environment.graphAPI.scopes);

	// Enable below code to attach token to all API requests
	protectedResourceMap.set(environment.apiConfig.uri, environment.apiConfig.scopes);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: [...environment.graphAPI.scopes],
		},
		loginFailedRoute: "/",
	};
}

const initialNavigation =
	!BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
		? withEnabledBlockingInitialNavigation() // Set to enabledBlocking to use Angular Universal
		: withDisabledInitialNavigation();

const ProviderOAuth = [
	provideRouter(routes, initialNavigation),
	provideHttpClient(withFetch(), withInterceptorsFromDi()),
	{ provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
	{ provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
	{ provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
	{
		provide: MSAL_INTERCEPTOR_CONFIG,
		useFactory: MSALInterceptorConfigFactory,
	},
	MsalService,
	MsalGuard,
	MsalBroadcastService,
	provideAnimationsAsync(),
	environment.production ? [] : provideQueryDevTools()
]

const ProviderIntegrated = [
	provideRouter(routes),
	provideHttpClient(withFetch(), withInterceptorsFromDi()),
	provideAnimationsAsync(),
	environment.production ? [] : provideQueryDevTools(),
]

export const appConfig: ApplicationConfig = {
	providers: [
		environment.authType === "OAuth" ? ProviderOAuth : ProviderIntegrated,
	],
};
